import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/eVuSSa-XjfU"
    notesSrc="https://www.docdroid.net/D7jNNpt/bible-group-homework-2-293-1.pdf"
    notesText="Bible Group Homework"
  >
    <SEO title="Love Song - The King is Coming" />
  </Layout>
)

export default SermonPost
